import React from 'react';
import './instructions.css';

const Instructions = () => {
  return (
    <div className="instructions">
      <h2>Instructions</h2>
      <p>To access datasets from C2SM, follow these instructions to submit your data requests:</p>
      <ol>
        <li>From the Data Selection menu, select your desired dataset.</li>
        <li>Use the progressive dropdown menu to filter the dataset by variable, date range, or other criteria.</li>
        <li>In the Area Selection menu, specify your area of interest or choose a grid point.</li>
        <li>Copy the generated request to your clipboard by clicking on the "Click to copy" button.</li>
        <li>Follow the then provided instructions to submit your request via GitHub.</li>
        <li>After submitting, monitor the GitHub issue thread where you submitted your request. A download link will be posted there once your data is ready.</li>
      </ol>
      <div className="additional-info">
      <p>
          Visit our <a href="https://c2sm.github.io/" target="_blank" rel="noopener noreferrer">C2SM User Landing Page</a> for further information about <a href="https://c2sm.github.io/tools/zephyr/" target="_blank" rel="noopener noreferrer">Zephyr</a>, available <a href="https://c2sm.github.io/datasets/" target="_blank" rel="noopener noreferrer">datasets</a> stored at C2SM, and <a href="https://c2sm.github.io/about/#how-to-get-access" target="_blank" rel="noopener noreferrer">how to get access</a> to the C2SM GitHub organisation.
      </p>      
      </div>
    </div>
  );
};

export default Instructions;


