import React, { Component } from "react";
import Toggle from "../toggle/toggle";
import SelectMap from "../leaflet/selectmap";

const Input = (props) => {
  return (
    <div className="input">
      <span className="label">{props.label}</span>
      <input
        type="number"
        step="any"
        id={props.id}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
};

class SelectArea extends Component {
  render() {
    var {
      selectGrid,
      lat,
      lon,
      minLat,
      minLon,
      maxLat,
      maxLon,
      onChangeValue,
      toggleselectGrid,
      updatePoint,
      updateRectangle,
    } = this.props;
    return (
      <React.Fragment>
        <Toggle
          left="Area"
          right="Grid Point"
          onChange={toggleselectGrid}
          checked={selectGrid}
        />
        {selectGrid ? (
          <div className="select">
            <Input
              label="Latitude"
              value={lat}
              id="lat"
              onChange={onChangeValue}
            />
            <Input
              label="Longitude"
              value={lon}
              id="lon"
              onChange={onChangeValue}
            />
          </div>
        ) : (
          <div className="select">
            <div>
              <Input
                label="Minimum latitude"
                value={minLat}
                id="minLat"
                onChange={onChangeValue}
              />
              <Input
                label="Minimum longitude"
                value={minLon}
                id="minLon"
                onChange={onChangeValue}
              />
            </div>
            <div>
              <Input
                label="Maximum latitude"
                value={maxLat}
                id="maxLat"
                onChange={onChangeValue}
              />
              <Input
                label="Maximum longitude"
                value={maxLon}
                id="maxLon"
                onChange={onChangeValue}
              />
            </div>
          </div>
        )}
        <div className="select-map">
          <SelectMap
            marker={selectGrid}
            lat={lat}
            lon={lon}
            minLat={minLat}
            minLon={minLon}
            maxLat={maxLat}
            maxLon={maxLon}
            updatePoint={updatePoint}
            updateRectangle={updateRectangle}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default SelectArea;
