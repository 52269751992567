import React, { Component } from "react";
import logo from "../../img/logo-no-background.png";
import "./header.css";

class Header extends Component {
  state = {};
  render() {
    return (
      <div className="header">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        [/ˈzef.ər/]
        <div className="title">Climate Data Extraction Tool</div>
      </div>
    );
  }
}

export default Header;
